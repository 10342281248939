<template>
  <div>
    <app-side-bar
      v-model="open"
      title="Benachrichtigungen"
    >
      <template #actions> </template>
      <v-container>
        <v-row>
          <v-col>
            <v-btn-toggle
              v-model="filter"
              class="filter-toggle"
              mandatory
              rounded="xl"
              color="primary"
              density="compact"
            >
              <v-btn
                value="UNREAD"
                class="px-5"
                width="120"
              >
                Ungelesen
              </v-btn>
              <v-btn
                value="ALL"
                class="px-5"
                width="120"
              >
                Alle
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col class="text-right">
            <v-menu :close-on-content-click="false">
              <template #activator="{ props }">
                <v-btn
                  v-bind="props"
                  color="primary"
                  icon="mdi-cog"
                  rounded="xl"
                  size="small"
                  variant="outlined"
                >
                </v-btn>
              </template>
              <v-card class="px-3">
                <profile-notification-settings :user-id="currentUser.id" />
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-list>
              <template v-if="filter === 'UNREAD'">
                <template
                  v-for="(item, i) in groupedNotifications"
                  :key="i"
                >
                  <v-list-group
                    :value="item.type"
                    fluid
                  >
                    <template #activator="{ props }">
                      <v-list-item
                        v-bind="props"
                        class="py-2"
                        @click="item.isOpen = !item.isOpen"
                      >
                        <template #prepend>
                          <v-avatar
                            v-if="item.latest.status === 'UNREAD'"
                            size="10"
                            color="accent"
                          />
                        </template>
                        <v-list-item-title
                          :class="item.latest.status !== 'UNREAD' ? 'grey--text' : ''"
                        >
                          {{ item.latest.title }}

                          <v-badge
                            color="accent"
                            :content="item.count"
                            inline
                          ></v-badge>
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-grey-darken-2">
                          {{ item.latest.text }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mt-1 text-caption">
                          {{ formatDate(item.latest.date_created) }}
                        </v-list-item-subtitle>
                        <template #append>
                          <v-icon>{{ item.isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                          <v-btn
                            icon
                            variant="flat"
                            class="ml-2"
                            @click.stop="setAllStatus(item.notifications, 'ARCHIVED')"
                          >
                            <v-icon color="grey-darken-2">mdi-delete</v-icon>
                          </v-btn>
                        </template>
                      </v-list-item>
                    </template>
                    <v-list-item
                      v-for="notif in item.notifications"
                      :key="notif.id"
                      class="py-2"
                      @click="clickItem(notif)"
                    >
                      <template #prepend>
                        <v-avatar
                          v-if="notif.status === 'UNREAD'"
                          size="10"
                          color="accent"
                        />
                      </template>
                      <v-list-item-title>{{ notif.title }}</v-list-item-title>
                      <v-list-item-subtitle>{{ notif.text }}</v-list-item-subtitle>
                      <v-list-item-subtitle class="mt-1 text-caption">{{
                        formatDate(notif.date_created)
                      }}</v-list-item-subtitle>
                      <template #append>
                        <v-btn
                          icon
                          variant="flat"
                          @click.stop="setStatus(notif.id, 'ARCHIVED')"
                        >
                          <v-icon color="grey-darken-2"> mdi-delete </v-icon>
                        </v-btn>
                      </template>
                    </v-list-item>
                  </v-list-group>
                </template>
              </template>
              <template v-else>
                <v-list-item
                  v-for="item in notifications"
                  :key="item.id"
                  @click="clickItem(item)"
                >
                  <template #prepend>
                    <v-avatar
                      v-if="item.status === 'UNREAD'"
                      size="10"
                      color="accent"
                    />
                  </template>
                  <v-list-item-title :class="item.status !== 'UNREAD' ? 'grey--text' : ''">
                    {{ item.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    class="text-grey-darken-2"
                    v-html="item.text"
                  />
                  <v-list-item-subtitle class="mt-2 text-caption">
                    {{ formatDate(item.date_created) }}
                  </v-list-item-subtitle>
                  <template #append>
                    <v-btn
                      icon
                      variant="flat"
                      @click.stop="setStatus(item.id, 'ARCHIVED')"
                    >
                      <v-icon color="grey-darken-2">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </app-side-bar>
  </div>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      notifications: [],
      filter: 'UNREAD',
      groupedNotifications: [],
    }
  },
  computed: {
    open: {
      get() {
        return !!this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      },
    },
  },
  watch: {
    filter() {
      this.loadData()
    },
    modelValue(value) {
      if (value) {
        this.loadData()
      }
    },
  },
  async mounted() {
    await this.loadData()
    if (this.filter === 'UNREAD' && !this.notifications.length) {
      this.filter = 'ALL'
    }
  },
  methods: {
    async loadData() {
      const tempNotifications = await this.fetchNotifications()

      this.notifications = tempNotifications

      if (this.filter === 'UNREAD') {
        this.groupNotifications()
      }
    },

    async fetchNotifications() {
      let status = {
        _neq: 'ARCHIVED',
      }

      if (this.filter === 'UNREAD') {
        status = {
          _eq: 'UNREAD',
        }
      }

      const response = await this.$cms.request(
        this.$readItems('notifications', {
          filter: { _and: [{ status }, { user: { _eq: this.currentUser.id } }] },
          fields: ['id', 'status', 'date_created', 'type', 'title', 'text', 'to'],
          limit: -1,
          sort: ['-date_created'],
        })
      )
      return response
    },
    groupNotifications() {
      const grouped = this.notifications.reduce((acc, notif) => {
        if (!acc[notif.type]) {
          acc[notif.type] = {
            type: notif.type,
            notifications: [],
            latest: notif,
            count: 0,
            isOpen: false,
          }
        }
        acc[notif.type].notifications.push(notif)
        acc[notif.type].count++
        if (new Date(notif.date_created) > new Date(acc[notif.type].latest.date_created)) {
          acc[notif.type].latest = notif
        }
        return acc
      }, {})
      this.groupedNotifications = Object.values(grouped)
    },
    async setStatus(notificationId, status) {
      await this.$cms.request(
        this.$updateItem('notifications', notificationId, {
          status,
        })
      )
      this.loadData()
    },

    async setAllStatus(notifications, status) {
      await this.$cms.request(
        this.$updateItems(
          'notifications',
          notifications.map((res) => res.id),
          {
            status,
          }
        )
      )
      this.loadData()
    },

    async clickItem(notification) {
      if (notification.status === 'UNREAD') {
        await this.setStatus(notification.id, 'READ')
      }
      this.$router.push(notification.to)
    },
  },
}
</script>
<style lang="scss">
.notificationlist .v-list-item__prepend {
  display: flex !important;
}

.filter-toggle {
  border: 1px solid #2f523e !important;
  .v-btn__overlay {
    opacity: 0 !important;
  }
}
</style>
