<template>
  <div>
    <v-menu
      transition="slide-y-transition"
      width="350"
    >
      <template #activator="{ props }">
        <v-btn
          v-bind="props"
          icon
          data-cy="menuButton"
        >
          <v-icon
            size="large"
            color="primary"
            >mdi-dots-grid</v-icon
          >
        </v-btn>
      </template>
      <v-list
        rounded="xl"
        density="compact"
      >
        <v-list-subheader class="text-h4 font-weight-bold text-black">Apps</v-list-subheader>
        <v-list-item>
          <v-row class="text-center">
            <v-col>
              <v-card
                class="pa-2"
                flat
                @click="$router.push('/dashboard/administrative')"
              >
                <v-card
                  height="60"
                  width="60"
                  color="teal"
                  rounded="xl"
                  class="pa-3 mx-auto"
                  flat
                >
                  <v-icon
                    size="35"
                    icon="mdi-view-dashboard"
                  ></v-icon>
                  <br /><br />
                </v-card>
                <div class="text-primary mt-2 text-caption">Dashboard</div>
              </v-card>
            </v-col>
            <v-col>
              <v-card
                class="pa-2"
                flat
                @click="$router.push('/rotationplan/start')"
              >
                <v-card
                  height="60"
                  width="60"
                  color="green"
                  rounded="xl"
                  class="pa-3 mx-auto"
                  flat
                >
                  <v-icon
                    size="35"
                    icon="mdi-account-switch"
                  ></v-icon>
                  <br /><br />
                </v-card>
                <div class="text-primary mt-2 text-caption">Rotationen</div>
              </v-card>
            </v-col>
            <v-col v-if="currentUser.facilities.some((f) => f.type === 'coordinate')">
              <v-card
                class="pa-2"
                flat
                @click="$router.push('/rotationplan/coordinator')"
              >
                <v-card
                  height="60"
                  width="60"
                  color="deep-purple"
                  rounded="xl"
                  class="pa-3 mx-auto"
                  flat
                >
                  <v-icon
                    size="35"
                    icon="mdi-table-account"
                  ></v-icon>
                  <br /><br />
                </v-card>
                <div class="mt-2 text-caption">Koordinator</div>
              </v-card>
            </v-col>
          </v-row>
        </v-list-item>
        <v-list-subheader class="text-h4 text-black font-weight-bold">Mehr</v-list-subheader>
        <v-list-item
          v-if="currentUser.facilities.some((f) => f.type === 'coordinate')"
          data-cy="coordinatorsettings"
          :to="'/rotationplan/externalslots'"
          class="pl-7"
        >
          <template #title>
            <div class="text-black text-body-2 font-weight-light">Rotationsplätze</div>
          </template>
        </v-list-item>
        <v-list-item
          v-for="(item, i) in settings.filter((setting) => !setting.hide)"
          :key="'more-' + i"
          :title="item.title"
          :data-cy="item.data_cy"
          :to="item.to"
          class="pl-7"
        >
          <!--template #prepend>
            <v-icon :icon="item.icon"></v-icon>
          </template-->
          <template #title>
            <div class="text-primary text-body-2 font-weight-light">
              {{ item.title }}
            </div>
          </template>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
  data: () => ({}),
  computed: {
    settings() {
      return [
        {
          title: 'Klinikstruktur',
          icon: 'mdi-hospital-building',
          color: 'secondary',
          disabled: false,
          hide: !this.currentUser.main_facility_admin,
          to: '/network/organisation',
        },
        {
          title: 'Offene Anfragen',
          icon: 'mdi mdi-checkbox-blank-circle-outline',
          color: 'info',
          disabled: false,
          hide: false,
          to: '/schedule/approvals',
        },
        {
          title: 'Verwaltendes Personal',
          icon: 'mdi mdi-checkbox-blank-circle-outline',
          color: 'info',
          disabled: false,
          hide: !this.currentUser.main_facility_admin,
          to: '/network/administrative',
        },
        {
          title: 'Rotierendes Personal',
          icon: 'mdi mdi-checkbox-blank-circle-outline',
          color: 'info',
          disabled: false,
          hide: false,
          to: '/network/rotating',
        },
        {
          title: 'Kontakt',
          icon: 'mdi mdi-checkbox-blank-circle-outline',
          color: 'info',
          disabled: false,
          hide: false,
          to: '/contact',
        },
        {
          title: 'Logout',
          to: '/auth/logout',
          disabled: false,
          hide: false,
          data_cy: 'logout',
        },
      ]
    },
  },
  created() {},
  mounted() {},
  methods: {},
}
</script>
<style lang="scss"></style>
